/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
 
            $("#mobile-nav").mmenu({
              navbar: false,
              "offCanvas": {
                "zposition": "next",
                "position": "right"
              }
              
            });
    var mmAPI = $("#mobile-nav").data( "mmenu" );
    $(".navbar-toggle").click(function() {
         mmAPI.open();
      });
/*
    $(".modal-fullscreen").on('show.bs.modal', function () {
  setTimeout( function() {
    $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
  }, 0);
});
$(".modal-fullscreen").on('hidden.bs.modal', function () {
  $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
});

$('#search-modal').on('shown.bs.modal', function () {
  $('#search-modal-field').focus();
});
*/

    },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var mySwiper = new Swiper ('.swiper-container', {

                  autoplay: 5000,
                  speed: 700,
                  preloadImages: false,
                  lazyLoading: true,
                  lazyLoadingInPrevNext: true,
                  keyboardControl: true

        });

        mySwiper.on('slideChangeStart', function (swiper) {
          console.log('slide change start');

          var activeIndex = swiper.activeIndex;
          var activeSlide = swiper.slides.eq(activeIndex);

          if (activeSlide.hasClass("inverse")) {
            $("nav.navbar").addClass("navbar-inverse");
            $("body").addClass("inverse");
          } else {
            $("nav.navbar").removeClass("navbar-inverse");
            $("body").removeClass("inverse");
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


      }
    },
    // About us page, note the change from about-us to about_us.
    'products': {
      init: function() {
        // JavaScript to be fired on the about us page

          var tn_array = Array();
         $('#nav-section li').each(function() {
             tn_array.push($(this).attr('data-menuanchor'));
          });


         $('#fullpage').fullpage({
          menu: '#nav-section',
          anchors: tn_array,
          scrollOverflow: true,
          verticalCentered: false,
          responsiveWidth: 768
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'services': {
      init: function() {
        // JavaScript to be fired on the about us page
          var tn_array = Array();
         $('#nav-section li').each(function() {
             tn_array.push($(this).attr('data-menuanchor'));
          });

         $('#fullpage').fullpage({
          menu: '#nav-section',
          anchors: tn_array,
          scrollOverflow: true,
          verticalCentered: false,
          responsiveWidth: 768
        });
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
